.flip-container {
	perspective: 1000px;
	background-color: black;
}

.flip-container, .front, .back {
	width: 100%;
	height: 100%;
}

/* flip speed goes here */
.flipper {
	transform-style: preserve-3d;
	position: relative;
	width: 100%;
	height: 100%;
	transform-origin: 100% 50%;
}

/* hide back of pane during swap */
.front, .back {
	-webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateX(0deg);
}

/* back, initially hidden pane */
.back {
	display: none;
	transform: rotateX(180deg);
}