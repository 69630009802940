@import 'normalize';
@import 'variables';
@import 'mixins';
@import 'loaders';
@import 'flipper';

body, html{
	font-family: 'Brown-Pro';
	font-style: normal;
	font-size: $font-size;
	width: 100%;
	height: 100%;
	‑webkit‑text‑size‑adjust: 100%;
	@media screen and (max-width: $bp-mobile) {
		font-size: $font-size-mobile;
	}
}

img{
	width: 100%;
	height: 100%;
}
p{
	line-height: 1.4em;
}
a{
	text-decoration: none;
	color: inherit;
}

.main-logo{
	@media screen and (min-width: $bp-mobile) {
		width: 7rem;
		margin-left: -3.5rem;
	}
}
.mobile-plans{
	display: block;
	padding-left: 20px;
    padding-right: 20px;
	@media screen and (min-width: $bp-mobile) {
		display: none;
	}
}
.richtext{
	&.no-indent{
		p{
			text-indent: 0;
		}
	}
	max-width: 42em;
	p{
		padding: 0;
		margin: 0;
		text-indent: 2.2em;
		&:first-of-type{
			text-indent: 0;
		}
	}
	a{
		color: $color-link;
		&:hover{
			color: black;
		}
	}
}
.subtext{
	max-width: 42em;
	font-size: 0.75rem;
	padding-bottom: 2rem;
	a{
		color: $color-link;
	}
}
.spacer{
	width: 100%;
	height: 0;
	margin-bottom: 2rem;
}
.parallax-container{
	position: relative;
	&::-webkit-scrollbar {
		display: none;
	}
	-webkit-overflow-scrolling: touch; /* lets it scroll lazy */
	
}
.parallax-elem{
	display: block;
	vertical-align: top;
	margin-bottom: 0px;
	position: relative;
	&.landscape{
		width: 45%;
	}
	&.portrait{
		width: 33%;
	}
	&.square{
		width: 27.5%;
	}
	&.news{
		width: 25%;
		z-index: 3;
		position: relative;
	}
	&.project{
		z-index: 2;
		position: relative;
		.lazyloaded{
			transform: scale(1);
		}
	}
	&.study{
		z-index: 1;
		position: relative;
		
		.home--imagelink--title{
			top: 85%;
		}
	}
	&:hover{
		.home--imagelink--title{
			opacity: 1;
		}
	}
	.parallax-elem--sizer{
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	&:first-of-type{
		margin-top: 3rem;
	}
	&:last-child{
		margin-bottom: 3rem;
	}
	img,video{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	@media screen and (max-width: $bp-mobile) {
		&.landscape{
			width: 70%;
		}
		&.portrait{
			width: 50%;
		}
		&.square{
			width: 60%;
		}
		&.news{
			width: 50%;
		}
	}
}
.front{
	background-color: white;
}
.home{
	background-color: white;
	width: 100%;
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	// max-width: $max-width;
	margin: 0 auto;
	@media screen and (max-width: $bp-mobile) {
		 max-width: 100%;
		 overflow-x: hidden;
	}
}
.home--imagelink{
	display: block;
}
.home--imagelink--title{
	display: block;
	text-align: center;
	padding-top: 0.5rem;
	font-size: $font-size*0.75;
	color: $color-grey;
	opacity: 0;
	transition: opacity 0.3s ease;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	position: absolute;
    width: 100%;
}
.home--news{
	text-align: center;

}
.home--news--text{
	font-size: $font-size*0.75;
}
.home--news-title{
	text-transform: uppercase;
	letter-spacing: 0.02em;
}
.home--news--projectlink{
	font-size: $font-size*0.75;
	color: $color-grey;
	&:before{
		content: "";
		display: inline-block;
		background-image: url('/assets/svg/arrow_right.svg');
		background-size: 1.3rem 0.7rem;
		background-position: center;
		background-repeat: no-repeat;
		width: 1rem;
		height: 0.7em;
		margin-right: 0.3rem;
	}
	&:hover{
		color: black;
		&:before{
			background-image: url('/assets/svg/arrow_right_b.svg');
		}
	}
}
.home--kontakt{
	position: fixed;
	bottom: 0;
	z-index: 100;
	left: 0;
	display: block;
	text-align: center;
	//padding-bottom: 1rem;
	margin-bottom: 1rem;
	font-size: $font-size*0.75;
	color: $color-grey;
	transition: opacity 0.3s ease;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	cursor: pointer;
    width: 100%;
    &:hover{
    	color: black;
    }
}
.about-nav{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}
.about-nav--inner{
	// max-width: $max-width;
	margin: 0 auto;
}
.about--close{
	text-align: center;
	display: block;
	padding-bottom: 1rem;
	padding-top: 1rem;
	font-size: $font-size*0.75;
	text-transform: uppercase;
}
.about{
	width: 100%;
	height: 100%;
	background-color: black;
	color: white;
	overflow: auto;
	padding-top: 100px;
	box-sizing: border-box;
	position: relative;
	// max-width: $max-width;
	margin: 0 auto;
	.subtext{
		padding-bottom: 0;
	}
	.about-contacttext{
		padding-bottom: 1rem;
	}
	.column-half{
		&:first-of-type{
			padding-right: 7.5px;
			padding-left: 20px;
		}
		padding-left: 7.5px;
		padding-right: 20px;

	}
	a{
		color: $color-link;
		&:hover{
			color: white;
		}
	}
}
.about--team-member{
	width: 50%;
	float: left;
	&:hover{
		.about--team-contact{
			opacity: 1;
			pointer-events: auto;
		}
	}
	@media screen and (max-width: $bp-mobile) {
		width: 100%;
		.about--team-contact{
			opacity: 1;
		}
	}
}
.about--team-img{
	width: 80%;
	display: block;
	margin: 0 auto;
}
.about--team-contact{
	opacity: 0;
	pointer-events: none;
	font-size: $font-size-small;
	text-align: center;
}

.project-lightbox{
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	background-color: white;
	.project-lightbox--prev{
		z-index: 28;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 50%;
		cursor: url('/assets/images/arrow_left.png'), auto;
	}
	.project-lightbox--next{
		z-index: 5;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 50%;
		cursor: url('/assets/images/arrow_right.png'), auto;
	}
	img{
		cursor: pointer;
		width: auto;
		height: auto;
		max-width: 100%;
		max-width: calc(100% - 6rem);
		max-height: 100%;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
	.media{
		width: 100%;
		height: auto;
		max-width: 100%;
		max-width: calc(100% - 6rem);
		max-height: 100%;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) scale(1);
		div{
			position: relative;
			text-align: center;
			video{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				max-height: 100vh;
			}
		}
		
	}

}
.lightbox-close{
	position: fixed;
	top: 0rem;
	right: 0rem;
	padding: 1rem;
	cursor: pointer;
	z-index: 10;
	display: inline-block;
	width: 1rem;
	height: 1rem;
	cursor: pointer;
	background-image: url('/assets/svg/close.svg');
	background-size: 0.75rem;
	background-position: center;
	background-repeat: no-repeat;
	float: right;
	cursor: pointer;
	&:hover{
		background-image: url('/assets/svg/close_b.svg');
	}
}

.project-nav{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	font-size: $font-size*0.75;
	color: $color-grey;
	z-index: 5;
	@media screen and (max-width: $bp-mobile) {
		z-index: 20;
		background-color: white;
		height: 3rem;
	}
	.project-nav--inner{
		// max-width: $max-width;
		margin: 0 auto;
		padding-top: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		box-sizing: border-box;
	}
	.project-next-text, .project-prev-text{
		display: none;
		color: black;
	}
	.project-prev{
		display: inline-block;
		cursor: pointer;
		background-image: url('/assets/svg/arrow_left.svg');
		background-size: 1rem 0.7rem;
		background-position: center;
		background-repeat: no-repeat;
		width: 1.5rem;
		height: 0.8rem;
		padding-top: 0.1rem;
		float: left;
		margin-right: 0.3rem;
		@media screen and (max-width: $bp-mobile) {
			height: 1rem;
			padding-top: 0.05rem;
		}
		&:hover{
			background-image: url('/assets/svg/arrow_left_b.svg');
		}
	}
	.project-next{
		display: inline-block;
		cursor: pointer;
		background-image: url('/assets/svg/arrow_right.svg');
		background-size: 1rem 0.7rem;
		background-position: center;
		background-repeat: no-repeat;
		width: 1.5rem;
		height: 0.8rem;
		padding-top: 0.1rem;
		float: left;
		margin-right: 0.4rem;
		@media screen and (max-width: $bp-mobile) {
			height: 1rem;
			padding-top: 0.05rem;
		}
		&:hover{
			background-image: url('/assets/svg/arrow_right_b.svg');
		}
	}
	.project-close{
		display: inline-block;
		width: 1rem;
		height: 1rem;
		cursor: pointer;
		background-image: url('/assets/svg/close.svg');
		background-size: 0.75rem;
		background-position: center;
		background-repeat: no-repeat;
		float: right;
		cursor: pointer;
		&:hover{
			background-image: url('/assets/svg/close_b.svg');
		}
	}
}
.columns{
	.column-half{
		box-sizing: border-box;
		float: left;
		width: 50%;
		min-height: 1px;
		@media screen and (max-width: $bp-mobile) {
			width: 100%;
			padding: 0 20px;
			&:first-of-type{
				padding: 0 20px;
			}
		}
	}
	&:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
	}
}

.project-container{
	width: 100%;
	height: 100%;
	font-size: 0;
	// max-width: $max-width;
	margin: 0 auto;
	position: relative;
	.project-title{
		position: fixed;
		top: 1rem;
		text-transform: uppercase;
		left: 50%;
		transform: translateX(-50%);
		font-size: 1rem;
		transition: top 0.5s ease;
		&.hidden{
			top:-2rem;
		}
		@media screen and (max-width: $bp-mobile) {
			z-index: 20;
			top: 0.9rem;
			transition: none;
		}
	}
	.gallery-column{
		display: inline-block;
		vertical-align: top;
		font-size: $font-size;
		padding-top: 3rem;
		padding-left: 1rem;
		padding-right: 0.5rem;
		padding-bottom: 3rem;
		box-sizing: border-box;
		width: 50%;
		text-align: right;
		.media{
			display: inline-block;
			position: relative;
			cursor: pointer;
			&.landscape{
				width: 100%;
			}
			&.portrait{
				width: 66.666%;
			}
			&.square{
				width: 83.25%;
			}
			&:last-child{
				@media screen and (min-width: $bp-mobile) {
					margin-bottom: 33vh;
				}
			}
			video{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
		@media screen and (max-width: $bp-mobile) {
			width: 100%;
			padding-bottom: 0;
			padding-left: 20px;
			padding-right: 20px;
			display: block;
			.media{
				width: 100%;
				pointer-events: none;
				&.landscape{
					width: 100%;
				}
				&.portrait{
					width: 100%;
				}
				&.square{
					width: 100%;
				}
			}
		}
	}
	.info-column{
		display: inline-block;
		vertical-align: top;
		font-size: 1rem;
		padding-top: 3rem;
		margin-left: 0.5rem;
		padding-left: 0.5rem;
		padding-right: 1rem;
		box-sizing: border-box;
		width: 50%;
		text-align: left;
		position: fixed;
		top: 0;
		left: 50%;
		height: 100%;
		// transition: padding 0.5s ease;
		&.is-bigger{
			padding-top: 1rem;
		}
		@media screen and (max-width: $bp-mobile) {
			width: 100%;
			position: relative;
			display: block;
			height: auto;
			padding-left: 20px;
			padding-right: 20px;
			margin-left: 0;
			padding-top: 0;
			left: 0;
			&.is-bigger{
				padding-top: 0;
			}
			.info-name{
				display: none;
			}
		}
		.plan-name{
			&:before{
				content: "Plan";
				display: block;
			}
		}
		.info-plan-container{
			height:100%;
			width: 100%;
			@media screen and (max-width: $bp-mobile) {
				display: none;
			}
		}
		.info-text-container{
			overflow-y: auto;
			height:100%;
			width: 100%;
			@media screen and (max-width: $bp-mobile) {
				transform: none!important;
			}
		}
		.info-plan{
			position: relative;
			width: 100%;
			img{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				max-height: 100%;
				max-width: 100%;
				height: auto;
				padding: 2rem;
				box-sizing: border-box;
			}
		}
		.info-toggle{
			position: absolute;
			left: 0.5rem;
			bottom: 1rem;
			color: $color-grey;
			z-index: 5;
			@media screen and (max-width: $bp-mobile) {
				display: none;
			}
			&:hover{
				color: black;
				cursor: pointer;
			}
		}
	}
}